import getBasePath from '@/lib/utils/getBasePath/getBasePath';
import { getSession } from '@auth0/nextjs-auth0';
import { NextApiRequest, NextApiResponse } from 'next';

/**
 * Get the idToken from the session for the current user. This can be used to
 * authenticate api calls. This should be used in the client side code.
 *
 * @returns {Promise<string>} - The idToken for the current user.
 */
export const getIdTokenClient = async (): Promise<string> => {
  try {
    /** Fetch the session from the api. */
    const response = await fetch(`${getBasePath()}/api/auth/session/`);
    /** Get the JSON from the response. */
    const jsonResponse = await response.json();
    return Promise.resolve(jsonResponse.session.idToken);
  } catch (error) {
    return Promise.reject(error);
  }
};

/**
 * Get the idToken from the session for the current user. This can be used to
 * authenticate backend api calls. This should be used in the server side code.
 *
 * @param {NextApiRequest} req - The request object.
 * @param {NextApiResponse} res - The response object.
 * @returns {string | null} The idToken for the current user.
 */
export const getIdTokenSSR = async (
  req: NextApiRequest,
  res: NextApiResponse
): Promise<string | null> => {
  try {
    /** Fetch the session from the api. */
    const session = await getSession(req, res);
    if (session && session.idToken) {
      return session.idToken;
    }
    return null;
  } catch (error) {
    return null;
  }
};
