import { sdk } from '@/lib/dataSource/lostApi/common';
import { IConversationTotalUnreadCountResponseData } from '@petcolove/lost--client--api-sdk/dist/concrete/sdks/interfaces/chat';

/**
 * The params for the total unread conversation
 *
 * @interface IConversationTotalUnreadCountParams
 */
interface IConversationTotalUnreadCountParams {
  /** The auth token */
  authToken: string;
  /** The user entity id */
  userEntityId: number;
}

/**
 * Get the total of unread conversation
 *
 * @param {IConversationTotalUnreadCountParams} params - The params for the total unread function
 * @returns {Promise<IConversationTotalUnreadCountResponseData>} The list of all
 *   conversations
 */
export async function totalUnread({
  authToken,
  userEntityId,
}: IConversationTotalUnreadCountParams): Promise<IConversationTotalUnreadCountResponseData> {
  try {
    const conversations = await sdk
      .conversationsTotalUnread({
        contextId: userEntityId,
        authToken,
      })
      .read();
    return conversations.data;
  } catch (error) {
    console.error(error);
    throw error;
  }
}
