import { sdk } from '@/lib/dataSource/lostApi/common';
import { IServiceMethodResponse } from '@petcolove/lost--client--api-sdk/dist/abstract/service/interfaces';
import { IMeDto } from '@petcolove/lost--client--api-sdk/dist/concrete/sdks/services/auth/dto';

/**
 * @param {string} authToken - This is the auth token for the current user
 * @returns {Promise<IMeDto>} The details of the current user
 */
export default async function getMe(authToken: string): Promise<IMeDto> {
  try {
    const me: IServiceMethodResponse = await sdk
      .me({ authToken: authToken })
      .read();
    const data = me.data as IMeDto;
    return data;
  } catch (error) {
    console.error(error);
    throw error;
  }
}
