import { IFooter } from './Footer';
import {
  bottomLinks,
  footerColumnFourLinks,
  footerColumnOneLinks,
  footerColumnThreeLinks,
  footerColumnTwoLinks,
  footerColumnFourLoggedInLinks,
} from './FooterLinks';

const currentYear = new Date().getFullYear();

const base: IFooter = {
  columnOne: footerColumnOneLinks,
  columnTwo: footerColumnTwoLinks,
  columnThree: footerColumnThreeLinks,
  columnFour: footerColumnFourLinks,
  columnFourLoggedIn: footerColumnFourLoggedInLinks,
  disclosure: `Use of this service, website, or application constitutes acceptance of all terms listed above. Petco Love Lost and its associated logos are trademarks of Petco Animal Supplies, Inc.™ ${currentYear}, Petco Love | Petco Animal Supplies, Inc. All rights reserved.`,
  bottomLinks: bottomLinks,
};

export const mockFooterProps = {
  base,
};
