import { defaultEventEmitter } from '@/lib/analytics/commonEmitter';
import { IEvent } from '@/lib/analytics/handlers/abstractAnalyticsHandler';
import { AnalyticsInitiatingComponent } from '@/lib/storageClasses/analyticsEventStorage';

/**
 * The flow type
 *
 * @type {Flow}
 */
type Flow = 'Lost' | 'Found' | 'All';

/**
 * The event name
 *
 * @constant {string}
 */
const eventName = 'start_pet_search';

/**
 * The interface for the user sign up event
 *
 * @interface IUserSignUpEvent
 */
export interface IStartPetSearchEvent {
  /** The clicked text */
  clickText: string;
  /** The search flow */
  flow: Flow;
  /** The component used to start event */
  component: AnalyticsInitiatingComponent;
}

/**
 * Handle the user sign up event
 *
 * @param {IStartPetSearchEvent} params - The event params
 */
export const startPetSearchEventHandler = ({
  clickText,
  flow,
  component,
}: IStartPetSearchEvent) => {
  const eventData: IEvent = {
    name: eventName,
    data: {
      click_text: clickText,
      flow_type: flow,
      component,
    },
  };
  defaultEventEmitter.handleEvent(eventData);
};
