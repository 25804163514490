import AbstractStorage from '@/lib/utils/storage/abstract-storage';
import SessionStorage from '@/lib/utils/storage/session-storage';
import React, { createContext, useContext, useEffect, useState } from 'react';

/**
 * Describe the storybook user context
 *
 * @interface IStorybookAuthContext
 */
interface IStorybookAuthContext {
  /**
   * A flag to determine if is storybook
   *
   * @memberof IStorybookAuthContext
   * @member {boolean} isStorybook
   */
  isStorybook: boolean;
  /**
   * The id token
   *
   * @memberof IStorybookAuthContext
   * @member {string | null} [idToken]
   */
  idToken?: string | null;
  /**
   * Set the id token
   *
   * @memberof IStorybookAuthContext
   * @member {(idToken: string) => void} [setIdToken]
   */
  setIdToken?: (idToken: string) => void;
}

/**
 * The storybook context
 *
 * @constant {React.Context<IStorybookAuthContext>} StorybookContext
 */
const StorybookContext = createContext<IStorybookAuthContext>({
  isStorybook: false,
});

/**
 * The storybook user context provider props
 *
 * @interface IStorybookAuthContextProviderProps
 */
interface IStorybookAuthContextProviderProps {
  /**
   * The provider children
   *
   * @memberof IStorybookAuthContextProviderProps
   * @member {React.ReactNode} children
   */
  children: React.ReactNode;
  /**
   * The id token storage
   *
   * @memberof IStorybookAuthContextProviderProps
   * @member {AbstractStorage} [idTokenStorage]
   */
  idTokenStorage?: AbstractStorage;
}

const defaultStorage = new SessionStorage();

/**
 * The hook to use the stored id token
 *
 * @interface IUseStoredIdToken
 */
interface IUseStoredIdToken {
  /**
   * The id token
   *
   * @memberof IUseStoredIdToken
   * @member {string | null} idToken
   */
  idToken: string | null;
  /**
   * The setter function for the id token that also stores it in the storage
   *
   * @memberof IUseStoredIdToken
   * @member {(idToken: string) => void} setIdToken
   * @param idToken
   * @returns {void}
   */
  setIdToken: (idToken: string | null) => void;
  /**
   * The storage key for the id token
   *
   * @memberof IUseStoredIdToken
   * @member {string} storageKey
   */
  storageKey: string;
}

/**
 * The hook to use the stored id token
 *
 * @param {AbstractStorage} storage - The storage
 * @returns {IUseStoredIdToken} - The stored id token
 */
export const useStoredIdToken = (
  storage: AbstractStorage = defaultStorage
): IUseStoredIdToken => {
  /**
   * The storage key for the id token
   *
   * @constant {string} storageKey
   */
  const storageKey = 'idToken';

  const [idToken, _setIdToken] = useState<string | null>(null);

  /** Hook retrieve the stored id token from storage */
  useEffect(() => {
    /**
     * The store id token
     *
     * @constant {string} storedIdToken
     */
    const storedIdToken = storage.get(storageKey);

    if (storedIdToken && typeof storedIdToken === 'string') {
      _setIdToken(storedIdToken);
    }
  }, [_setIdToken, storage]);

  /**
   * The setter function for the id token that also stores it in the storage
   *
   * @param {string} idToken - The id token
   */
  const setIdToken = (idToken: string | null) => {
    idToken === null
      ? storage.delete(storageKey)
      : storage.set(storageKey, idToken);

    _setIdToken(idToken);
  };

  return {
    idToken,
    setIdToken,
    storageKey,
  };
};

/**
 * The storybook provider
 *
 * @param {IStorybookAuthContextProviderProps} props - The props
 * @returns {React.ReactElement} - The storybook provider
 */
export const StorybookProvider = ({
  children,
  idTokenStorage,
}: IStorybookAuthContextProviderProps) => {
  /**
   * The flag to determine if is storybook
   *
   * @constant {boolean} isStorybook
   */
  const isStorybook = process.env.STORYBOOK_BASE_URL !== undefined;
  const { idToken, setIdToken } = useStoredIdToken(idTokenStorage);

  return (
    <StorybookContext.Provider
      value={{
        setIdToken,
        idToken,
        isStorybook,
      }}
    >
      {children}
    </StorybookContext.Provider>
  );
};

/**
 * Use the storybook user context
 *
 * @returns {IStorybookAuthContext} - The storybook user context
 */
export const useStorybookAuthContext = (): IStorybookAuthContext => {
  const storybookContext = useContext(StorybookContext);
  const { isStorybook } = storybookContext;

  return isStorybook ? storybookContext : { isStorybook };
};
